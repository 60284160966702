import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import DashboardLayout2 from "@/views/Layout/DashboardLayout2.vue";
import NotFound from "@/views/GeneralViews/NotFoundPage.vue";

const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue");
const MaterialType = () => import("@/views/Master/MaterialType.vue");
const MaterialGroup = () => import("@/views/Master/MaterialGroup.vue");
const ServiceCategory = () => import("@/views/Master/ServiceCategory.vue");
const ServiceGroup = () => import("@/views/Master/ServiceGroup.vue");
const HoldingCode = () => import("@/views/Master/HoldingCode.vue");
const CompanyCode = () => import("@/views/Master/CompanyCode.vue");
const PlantCode = () => import("@/views/Master/PlantCode.vue");
const PlantSp = () => import("@/views/Master/PlantSp.vue");
const LocationCode = () => import("@/views/Master/LocationCode.vue");
const ValuationClass = () => import("@/views/Master/ValuationClass.vue");
const CatalogStatus = () => import("@/views/Master/CatalogStatus.vue");
const CatalogType = () => import("@/views/Master/CatalogType.vue");
const EquipmentCode = () => import("@/views/Master/EquipmentCode.vue");
const ManrefType = () => import("@/views/Master/ManrefType.vue");
const SourceType = () => import("@/views/Master/SourceType.vue");
const SalesOrganization = () => import("@/views/Master/SalesOrganization.vue");
const DistributionChannel = () =>
  import("@/views/Master/DistributionChannel.vue");
const Warehouse = () => import("@/views/Master/Warehouse.vue");
const AccountAssignmentGroup = () =>
  import("@/views/Master/AccountAssignmentGroup.vue");
const ProductHierarchy = () => import("@/views/Master/ProductHierarchy.vue");
const ProfitCenter = () => import("@/views/Master/ProfitCenter.vue");
const PriceControl = () => import("@/views/Master/PriceControl.vue");
const PurchasingGroup = () => import("@/views/Master/PurchasingGroup.vue");
const AccountCategoryRefference = () =>
  import("@/views/Master/AccountCategoryRefference.vue");
const SpecialProcurementType = () =>
  import("@/views/Master/SpecialProcurementType.vue");
const ProcurementType = () => import("@/views/Master/ProcurementType.vue");
const LoadingGroup = () => import("@/views/Master/LoadingGroup.vue");
const AbcIndicator = () => import("@/views/Master/AbcIndicator.vue");
const MrpController = () => import("@/views/Master/MrpController.vue");
const MrpTypes = () => import("@/views/Master/MrpTypes.vue");
const LotSize = () => import("@/views/Master/LotSize.vue");
const ItemCategoryGroup = () => import("@/views/Master/ItemCategoryGroup.vue");
const TaxClassification = () => import("@/views/Master/TaxClassification.vue");
const Division = () => import("@/views/Master/Division.vue");
const TransportationGroup = () =>
  import("@/views/Master/TransportationGroup.vue");
const StorageSection = () => import("@/views/Master/StorageSection.vue");
const StorageType = () => import("@/views/Master/StorageType.vue");

const StandardItemName = () =>
  import("@/views/Dictionary/StandardItemName.vue");
const CharacteristicItemName = () =>
  import("@/views/Dictionary/CharacteristicItemName.vue");
const CharacteristicValue = () =>
  import("@/views/Dictionary/CharacteristicValue.vue");
const CharacteristicMaster = () =>
  import("@/views/Dictionary/CharacteristicMaster.vue");
const UnitOfMeasure = () => import("@/views/Dictionary/UnitOfMeasure.vue");
const Abbreviation = () => import("@/views/Dictionary/Abbreviation.vue");
const Manufacture = () => import("@/views/Dictionary/Manufacture.vue");
const NatoGroupClass = () => import("@/views/Dictionary/NatoGroupClass.vue");
const SmartcatAttachment = () =>
  import("@/views/Dictionary/SmartcatAttachment.vue");
const ScopeofSupply = () => import("@/views/Dictionary/ScopeOfSupply.vue");
const DrawingMaster = () => import("@/views/Dictionary/DrawingMaster.vue");

const Message = () => import("@/views/Message/Message.vue");
const MessageView = () => import("@/views/Message/View.vue");
const Compose = () => import("@/views/Message/Compose.vue");
const Inbox = () => import("@/views/Message/Inbox.vue");
const InboxView = () => import("@/views/Message/InboxView.vue");
const Sent = () => import("@/views/Message/Sent.vue");
const SentView = () => import("@/views/Message/SentView.vue");

const SapConnection = () => import("@/views/SAP/SapConnection.vue");
const MasterDataSync = () => import("@/views/SAP/MasterDataSync.vue");
const SapSyncTask = () => import("@/views/SAP/SapSyncTask.vue");
const SapSync = () => import("@/views/SAP/SapSync.vue");
const ConfigSapSync = () => import("@/views/SAP/Config/ConfigSapSync.vue");
const ConfigSapSyncParams = () =>
  import("@/views/SAP/Config/ConfigSapSyncParams.vue");

const ManageAccount = () => import("@/views/Manage/ManageAccount.vue");
const ManageAccountAdd = () => import("@/views/Manage/ManageAccountAdd.vue");
const ManageAccountEdit = () => import("@/views/Manage/ManageAccountEdit.vue");
const Jabatan = () => import("@/views/Manage/Jabatan.vue");
const ManageRole = () => import("@/views/Manage/ManageRole.vue");
const ManageRoleAdd = () => import("@/views/Manage/ManageRoleAdd.vue");
const ManageRoleEdit = () => import("@/views/Manage/ManageRoleEdit.vue");
const ManagePermission = () => import("@/views/Manage/ManagePermission.vue");
const Department = () => import("@/views/Manage/Department.vue");
const Section = () => import("@/views/Manage/Section.vue");
const SubSection = () => import("@/views/Manage/SubSection.vue");

const ManageMenu = () => import("@/views/Setting/ManageMenu.vue");
const ManageSubMenu = () => import("@/views/Setting/ManageSubMenu.vue");
const MailConfiguration = () => import("@/views/Setting/MailConfiguration.vue");
const Notification = () => import("@/views/Setting/Notification.vue");
const McrStatus = () => import("@/views/Setting/McrStatus.vue");
const Faq = () => import("@/views/Setting/Faq.vue");
const FaqDetail = () => import("@/views/Setting/FaqDetail.vue");
// const DatabaseBackup                   = () => import ('@/views/Setting/DatabaseBackup.vue');
const MailTemplates = () => import("@/views/Setting/MailTemplates.vue");
const Tasks = () => import("@/views/Setting/Tasks.vue");
const TaskMenus = () => import("@/views/Setting/TaskMenus.vue");
// const TaskMenus                     = () => import('@/views/Setting/contohDraggable.vue');
const Workflows = () => import("@/views/Setting/Workflows.vue");
const WorkflowsEdit = () => import("@/views/Setting/WorkflowsEdit.vue");
const TableColumn = () => import("@/views/Setting/TableColumn.vue");
const Script = () => import("@/views/Setting/Script.vue");
const Form = () => import("@/views/Setting/Form.vue");

const Settings = () => import("@/views/Setting/Settings.vue");

// REPORT
const CheckDuplicate = () => import("@/views/Report/CheckDuplicate.vue");
const CheckDuplicateByPartNumberReport = () =>
  import("@/views/Report/CheckDuplicateByPartNumberReport.vue");
const WorkReport = () => import("@/views/Report/WorkReport.vue");
const Export = () => import("@/views/Report/Export.vue");
const Import = () => import("@/views/Report/Import.vue");
const ImportHistory = () => import("@/views/Report/ImportHistory.vue");
const SapImport = () => import("@/views/Report/SapImport.vue");
const AppAuditing = () => import("@/views/Report/AppAuditing.vue");
const ModelLog = () => import("@/views/Report/ModelLog.vue");
const TransactionLog = () => import("@/views/Report/TransactionLog.vue");
const ActivityLog = () => import("@/views/Report/ActivityLog.vue");
const KpiMcrApproval = () => import("@/views/Report/KpiMcrApproval.vue");
const KpiMcrCreation = () => import("@/views/Report/KpiMcrCreation.vue");
const McrProcess = () => import("@/views/Report/McrProcess.vue");
const KonsolidasiReport = () => import("@/views/Report/KonsolidasiReport.vue");
const McrReport = () => import("@/views/Report/McrReport.vue");

const Login = () => import("@/views/User/NewLogin.vue");
const Profile = () => import("@/views/User/Profile.vue");
const ChangePassword = () => import("@/views/User/ChangePassword.vue");
const ForgotPassword = () => import("@/views/ForgotPassword.vue");
const ResetPassword = () => import("@/views/ResetPassword.vue");
const NewLogin = () => import("@/views/User/NewLogin.vue");

const DashboardMcrStatus = () => import("@/views/Dashboard/McrStatus.vue");
const MaterialStatus = () => import("@/views/Dashboard/MaterialStatus.vue");
const MyWork = () => import("@/views/Dashboard/MyWork.vue");
const MyTask = () => import("@/views/Dashboard/MyTask.vue");
const MyTaskShow = () => import("@/views/Dashboard/MyTaskShow.vue");
const MyTaskClosed = () => import("@/views/Dashboard/MyTaskClosed.vue");
const MyNotification = () => import("@/views/Dashboard/MyNotification.vue");
const SearchMaterial = () => import("@/views/GeneralLayout/SearchMaterial.vue");
const SearchMaterialSearch = () =>
  import("@/views/GeneralLayout/SearchMaterialSearch.vue");
const SearchService = () => import("@/views/GeneralLayout/SearchService.vue");
const SearchServiceSearch = () =>
  import("@/views/GeneralLayout/SearchServiceSearch.vue");
const SearchCharacteristic = () =>
  import("@/views/GeneralLayout/SearchCharacteristic.vue");
const SearchMaterialSimple = () =>
  import("@/views/GeneralLayout/SearchMaterialSimple.vue");

// MATERIAL CATALOG REQUEST
const MaterialRequest = () => import("@/views/Material/MaterialRequest.vue");

// MATERIAL CATALOG REQUEST - DAP
const DraftList = () => import("@/views/Material/DraftList.vue");
const McrForm = () => import("@/views/Material/McrForm.vue");

// STOCK CALCULATION
const StockCalcFastByHistoryDemand = () =>
  import("@/views/StockCalculation/StockCalcFastByHistoryDemand.vue");
  // import("@/views/StockCalculation/OLDStockCalcFastByHistoryDemand.vue");
const StockCalcFastByManual = () =>
  import("@/views/StockCalculation/StockCalcFastByManual.vue");
const StockCalcSlowByHistoryDemand = () =>
  import("@/views/StockCalculation/StockCalcSlowByHistoryDemand.vue");
const StockCalcSlowByManual = () =>
  import("@/views/StockCalculation/StockCalcSlowByManual.vue");
const StockCalcRecommendation = () =>
  import("@/views/StockCalculation/StockCalcRecommendation.vue");
const StockCalcMultiple = () =>
  import("@/views/StockCalculation/StockCalcMultiple.vue");
  // import("@/views/StockCalculation/OLDStockCalcMultiple.vue");
// const StockCalcBenefitCostRatio = () =>
//   import("@/views/StockCalculation/StockCalcBenefitCostRatio.vue");

// MATERIAL DUPLICATION
const MaterialDuplication = () =>
  import("@/views/MaterialDuplication/MaterialDuplication.vue");

const TestView = () => import("@/views/Test.vue");

// ABC Evaluation
// const RCSPage                          = () => import ('@/views/RCS/MainRCS.vue')
const ABCEvaluation = () => import("@/views/ABCEvaluation/ABCEvaluation.vue");
//BOM
const BillOfMaterial = () => import("@/views/BillOfMaterial/Bom.vue");

const master = {
  path: "/master",
  component: DashboardLayout,
  redirect: "/master",
  name: "General Master Data",
  children: [
    {
      path: "manufacturer",
      name: "Manufacturer",
      component: Manufacture,
    },
    {
      path: "material-type",
      name: "Material Type",
      component: MaterialType,
    },
    {
      path: "material-group",
      name: "Material Group",
      component: MaterialGroup,
    },
    {
      path: "unit-of-measure",
      name: "Unit of Measure",
      component: UnitOfMeasure,
    },
    {
      path: "holding-code",
      name: "Holding Code",
      component: HoldingCode,
    },
    {
      path: "company-code",
      name: "Company Code",
      component: CompanyCode,
    },
    {
      path: "plant-code",
      name: "Plant Code",
      component: PlantCode,
    },
    {
      path: "plant-sp",
      name: "Plant Sp",
      component: PlantSp,
    },
    {
      path: "location-code",
      name: "Location Code",
      component: LocationCode,
    },
    {
      path: "valuation-class",
      name: "Valuation Class",
      component: ValuationClass,
    },
    {
      path: "catalog-status",
      name: "Catalog Status",
      component: CatalogStatus,
    },
    {
      path: "catalog-type",
      name: "Catalog Type",
      component: CatalogType,
    },
    {
      path: "manref-type",
      name: "Manref Type",
      component: ManrefType,
    },
    {
      path: "source-type",
      name: "Source Type",
      component: SourceType,
    },
    {
      path: "sales-organization",
      name: "Sales Organization",
      component: SalesOrganization,
    },
    {
      path: "distribution-channel",
      name: "Distribution Channel",
      component: DistributionChannel,
    },
    {
      path: "warehouse",
      name: "Warehouse",
      component: Warehouse,
    },
    {
      path: "storage-section",
      name: "Storage Section",
      component: StorageSection,
    },
    {
      path: "storage-type",
      name: "Storage Type",
      component: StorageType,
    },
    {
      path: "account-assignment-group",
      name: "Account Assignment Group",
      component: AccountAssignmentGroup,
    },
    {
      path: "product-hierarchy",
      name: "Product Hierarchy",
      component: ProductHierarchy,
    },
    {
      path: "profit-center",
      name: "Profit Center",
      component: ProfitCenter,
    },
    {
      path: "price-control",
      name: "Price Control",
      component: PriceControl,
    },
    {
      path: "purchasing-group",
      name: "Purchasing Group",
      component: PurchasingGroup,
    },
    {
      path: "account-category-refference",
      name: "Account Category Refference",
      component: AccountCategoryRefference,
    },
    {
      path: "special-procurement-type",
      name: "Special Procurement Type",
      component: SpecialProcurementType,
    },
    {
      path: "procurement-type",
      name: "Procurement Type",
      component: ProcurementType,
    },
    {
      path: "loading-group",
      name: "Loading Group",
      component: LoadingGroup,
    },
    {
      path: "abc-indicator",
      name: "ABC Indicator",
      component: AbcIndicator,
    },
    {
      path: "mrp-controller",
      name: "MRP Controller",
      component: MrpController,
    },
    {
      path: "mrp-types",
      name: "MRP Types",
      component: MrpTypes,
    },
    {
      path: "lot-size",
      name: "Lot Size",
      component: LotSize,
    },
    {
      path: "item-category-group",
      name: "Item Category Group",
      component: ItemCategoryGroup,
    },
    {
      path: "tax-classification",
      name: "Tax Classification",
      component: TaxClassification,
    },
    {
      path: "division",
      name: "Division",
      component: Division,
    },
    {
      path: "transportation-group",
      name: "Transportation Group",
      component: TransportationGroup,
    },
  ],
};

const material = {
  path: "/material",
  component: DashboardLayout,
  redirect: "/material",
  name: "Material & Service Catalog Request",
  children: [
    {
      path: "draft-list",
      name: "Draft List",
      component: DraftList,
    },
    {
      path: "material-request",
      name: "Material Request",
      component: MaterialRequest,
    },
    // DAP MATERIAL CATALOG REQUEST
    {
      path: "mcr-form/:mcr_type/:mcr_code/:mcr_item_code/:token/:type",
      name: "MCR Form",
      component: McrForm,
    },
  ],
};

const stockCalculation = {
  path: "/stock-calculation",
  component: DashboardLayout,
  redirect: "/stock-calculation",
  name: "Stock Calculation",
  children: [
    {
      path: "fm-single-material-code",
      name: "Single Material Code",
      component: StockCalcFastByHistoryDemand,
    },
    // {
    //     path: 'fm-benefit-cost-ratio',
    //     name: 'Benefit Cost Ratio',
    //     component: StockCalcFastByBenefitCostRatio
    // },
    {
      path: "fm-manual",
      name: "Manual Input",
      component: StockCalcFastByManual,
    },
    {
      path: "sm-history-demand",
      name: "Single Material Code",
      component: StockCalcSlowByHistoryDemand,
    },
    {
      path: "sm-manual",
      name: "Manual Input",
      component: StockCalcSlowByManual,
    },
    {
      path: "model-recommendation",
      name: "Model Recommendation",
      component: StockCalcRecommendation,
    },
    {
      path: "stock-calc-multiple",
      name: "Multiple Selection Material Code",
      component: StockCalcMultiple,
    },
    // {
    //   path: "stock-calc-benefit-cost-ratio",
    //   name: "Benefit Cost Ratio",
    //   component: StockCalcBenefitCostRatio,
    // },
  ],
};

const dictionary = {
  path: "/dictionary",
  component: DashboardLayout,
  redirect: "/dictionary",
  name: "Dictionary",
  children: [
    {
      path: "standard-item-name",
      name: "Standard Item Name",
      component: StandardItemName,
    },
    {
      path: "characteristic-master",
      name: "Characteristic Master",
      component: CharacteristicMaster,
    },
    {
      path: "characteristic-item-name",
      name: "Characteristic Item Name",
      component: CharacteristicItemName,
    },
    {
      path: "characteristic-value",
      name: "Characteristic Value",
      component: CharacteristicValue,
    },
    {
      path: "abbreviation",
      name: "Abbreviation",
      component: Abbreviation,
    },
    {
      path: "nato-group-class",
      name: "Nato Group Class",
      component: NatoGroupClass,
    },
    {
      path: "smartcat-attachment",
      name: "SmartCat Attachment",
      component: SmartcatAttachment,
    },
    {
      path: "scope-of-supply",
      name: "Scope Of Supply",
      component: ScopeofSupply,
    },
    {
      path: "equipment",
      name: "Equipment",
      component: EquipmentCode,
    },
    {
      path: "bom",
      name: "Bill Of Material",
      component: BillOfMaterial,
    },
    {
      path: "drawing-master",
      name: "Drawing Master",
      component: DrawingMaster,
    },
  ],
};

const materialDuplication = {
  path: "/material-duplication",
  component: DashboardLayout,
  redirect: "/material-duplication",
  name: "Material Duplication",
  children: [
    {
      path: "display",
      name: "Display",
      component: MaterialDuplication,
    },
  ],
};

const message = {
  path: "/message",
  component: DashboardLayout,
  name: "Message",
  children: [
    {
      path: "/message",
      name: "Message List",
      component: Message,
    },
    {
      path: "/message/view/:id",
      name: "MessageView",
      component: MessageView,
    },
  ],
};

const sap = {
  path: "/sap",
  component: DashboardLayout,
  redirect: "/sap",
  name: "SAP",
  children: [
    ,
    {
      path: "sap-sync",
      name: "SAP Sync",
      component: SapSync,
    },
    {
      path: "sap-sync-task",
      name: "SAP Sync Task",
      component: SapSyncTask,
    },
  ],
};

const manage = {
  path: "/manage",
  component: DashboardLayout,
  redirect: "/manage",
  name: "Manage Account",
  children: [
    {
      path: "manage-account",
      name: "Account List",
      component: ManageAccount,
    },
    {
      path: "manage-account-add",
      name: "Manage Account Add",
      component: ManageAccountAdd,
    },
    {
      path: "manage-account-edit",
      name: "Manage Account Edit",
      component: ManageAccountEdit,
    },
    {
      path: "jabatan",
      name: "Jabatan",
      component: Jabatan,
    },
    {
      path: "manage-role",
      name: "Manage Role",
      component: ManageRole,
    },
    {
      path: "manage-role-add",
      name: "Manage Role Add",
      component: ManageRoleAdd,
    },
    {
      path: "manage-role-edit/:id",
      name: "Manage Role Edit",
      component: ManageRoleEdit,
    },
    {
      path: "manage-permission",
      name: "Manage Permission",
      component: ManagePermission,
    },
    {
      path: "department",
      name: "Department",
      component: Department,
    },
    {
      path: "section",
      name: "Section",
      component: Section,
    },
    {
      path: "sub-section",
      name: "Sub Section",
      component: SubSection,
    },
  ],
};

const setting = {
  path: "/setting",
  component: DashboardLayout,
  redirect: "/setting",
  name: "General Setting",
  children: [
    {
      path: "manage-menu",
      name: "Manage Menu",
      component: ManageMenu,
    },
    {
      path: "manage-sub-menu",
      name: "Manage Sub Menu",
      component: ManageSubMenu,
    },
    {
      path: "mail-configuration",
      name: "Mail Configuration",
      component: MailConfiguration,
    },
    {
      path: "notification",
      name: "Notification",
      component: Notification,
    },
    {
      path: "faq",
      name: "FAQ",
      component: Faq,
    },
    {
      path: "faq-detail/:id",
      name: "Detail FAQ",
      component: FaqDetail,
      params: true,
    },
    // {
    //     path: 'database-backup',
    //     name: 'Database Backup',
    //     component: DatabaseBackup
    // },
    {
      path: "mail-templates",
      name: "Mail Templates",
      component: MailTemplates,
    },
    {
      path: "tasks",
      name: "Tasks",
      component: Tasks,
    },
    {
      path: "task-menus",
      name: "Task Menus",
      component: TaskMenus,
    },
    {
      path: "workflows",
      name: "Workflows",
      component: Workflows,
    },
    {
      path: "workflows-edit/:id",
      name: "Workflows Edit",
      component: WorkflowsEdit,
    },
    {
      path: "table-column",
      name: "Table Column",
      component: TableColumn,
    },
    {
      path: "script",
      name: "Script",
      component: Script,
    },
    {
      path: "form",
      name: "Form",
      component: Form,
    },
    {
      path: "settings",
      name: "Settings",
      component: Settings,
    },
    {
      path: "sap-connection",
      name: "SAP Connection",
      component: SapConnection,
    },
    {
      path: "config-sap-sync",
      name: "Config Sap Sync",
      component: ConfigSapSync,
    },
    {
      path: "config-sap-sync-params",
      name: "Config Sap Sync Params",
      component: ConfigSapSyncParams,
    },
  ],
};

// REPORT
const report = {
  path: "/report",
  component: DashboardLayout,
  redirect: "/report",
  name: "Report",
  children: [
    {
      path: "check-duplicate",
      name: "Check Duplicate",
      component: CheckDuplicate,
    },
    {
      path: "check-duplicate-by-part-number-report",
      name: "Check Duplicate By Part Number Report",
      component: CheckDuplicateByPartNumberReport,
    },
    {
      path: "work-report",
      name: "Work Report",
      component: WorkReport,
    },
    {
      path: "export",
      name: "Export",
      component: Export,
    },
    {
      path: "import",
      name: "Import",
      component: Import,
    },
    {
      path: "import-history",
      name: "Import History",
      component: ImportHistory,
    },
    {
      path: "sap-import",
      name: "SAP Import",
      component: SapImport,
    },
    {
      path: "material-status",
      name: "Material Status",
      component: MaterialStatus,
    },
    {
      path: "kpi-mcr-approval",
      name: "KPI MCR Status",
      component: KpiMcrApproval,
    },
    {
      path: "app-auditing",
      name: "App Auditing",
      component: AppAuditing,
    },
    {
      path: "model-log",
      name: "Model Log",
      component: ModelLog,
    },
    {
      path: "transaction-log",
      name: "Transaction Log",
      component: TransactionLog,
    },
    {
      path: "activity-log",
      name: "Activity Log",
      component: ActivityLog,
    },
    {
      path: "kpi-mcr-approval",
      name: "KPI MCR Status",
      component: KpiMcrApproval,
    },
    {
      path: "kpi-mcr-creation",
      name: "KPI MCR Creation",
      component: KpiMcrCreation,
    },
    {
      path: "mcr-process",
      name: "MCR Process",
      component: McrProcess,
    },
    {
      path: "konsolidasi-report",
      name: "Konsolidasi Report",
      component: KonsolidasiReport,
    },
    {
      path: "mcr-report",
      name: "Mcr Report",
      component: McrReport,
    },
  ],
};

const rcs = {
  path: "/abc-evaluation",
  component: DashboardLayout,
  redirect: "/abc-evaluation",
  name: "ABC Evaluation",
  children: [
    {
      path: "worksheet",
      component: ABCEvaluation,
      name: "Worksheet",
    },
  ],
};

const login = {
  path: "/user",
  redirect: "/user",
  name: "User",
  children: [
    {
      path: "login",
      name: "Login",
      component: Login,
    },
  ],
};

const user = {
  path: "/user",
  component: DashboardLayout2,
  redirect: "/user",
  name: "User",
  children: [
    {
      path: "profile",
      name: "Profile",
      component: Profile,
    },
    {
      path: "change-password",
      name: "Change Password",
      component: ChangePassword,
    },
  ],
};

const dashboard = {
  path: "/dashboard",
  component: DashboardLayout,
  redirect: "/dashboard",
  name: "Dashboard",
  children: [
    {
      path: "my-work",
      name: "My Work",
      component: MyWork,
    },
    {
      path: "my-task",
      name: "My Task",
      component: MyTask,
    },
    {
      path: "my-task-show/:id",
      name: "My Task Show",
      component: MyTaskShow,
    },
    {
      path: "my-task-show/:id/:mcr_type/:mcr_code/:mcr_item_code/:token/:type", // Show modal untuk process (approval) MCR
      name: "MCR Form Approval",
      component: MyTaskShow,
    },
    {
      path: "my-task-closed/:id",
      name: "My Task Closed",
      component: MyTaskClosed,
    },
    {
      path: "my-task-closed/:id/:mcr_type/:mcr_code/:mcr_item_code/:token/:type", // Show modal untuk view (detail) MCR
      name: "MCR Form Approval Detail",
      component: MyTaskClosed,
    },
    {
      path: "my-notification",
      name: "My Notification",
      component: MyNotification,
    },
    {
      path: "mcr-status",
      name: "MCR Status",
      component: DashboardMcrStatus,
    },
    {
      path: "material-status",
      name: "Material Status",
      component: MaterialStatus,
    },
  ],
};

const general_layout = {
  path: "/general_layout",
  component: DashboardLayout,
  redirect: "/general-layout",
  name: "General Layout",
  children: [
    {
      path: "search-material",
      name: "Search Material",
      component: SearchMaterial,
    },
    {
      path: "end-user-search-material",
      name: "Search Material Search",
      component: SearchMaterialSearch,
    },
    {
      path: "search-service",
      name: "Search Service",
      component: SearchService,
    },
    {
      path: "search-service-search",
      name: "Search Service Search",
      component: SearchServiceSearch,
    },
    {
      path: "search-characteristic",
      name: "Search Characteristic",
      component: SearchCharacteristic,
    },
    {
      path: "search-characteristic-search",
      name: "Search Characteristic Search",
      component: SearchMaterialSearch,
    },
    {
      path: "simple-search-material",
      name: "Simple Search Material",
      component: SearchMaterialSimple,
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Dashboard",
  },
  // {
  //     path: '/login',
  //     name: 'Login',
  //     component: Login
  // },
  {
    path: "/login",
    name: "Login",
    component: NewLogin,
  },
  {
    path: "/relogin",
    name: "Re-Login",
    component: Login,
  },
  {
    path: "/not-found",
    name: "Not Found",
    component: NotFound,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password/:token",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    name: "Dashboard layout",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
    ],
  },
  {
    path: "/test",
    component: DashboardLayout,
    redirect: "/test",
    name: "Test",
    children: [
      {
        path: "test-view",
        name: "Test View",
        component: TestView,
      },
    ],
  },
  master,
  material,
  stockCalculation,
  dictionary,
  materialDuplication,
  message,
  sap,
  manage,
  setting,
  report,
  user,
  dashboard,
  general_layout,
  rcs,
];

export default routes;
